import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { cloneObject, getValue } from '@zipari/web-utils';
import { validCXEvents, validGAEvents, validPosthogEvents, workflowContext } from '@zipari/shared-sbp-constants';
import { scrollToTop } from '@zipari/shared-sbp-modules';
import { QuoteDetailPayload, Workflow, WorkflowStepDetails, WorkflowStepSchema } from '@zipari/shared-sbp-models';
import { AnalyticsService, LoggerService, ModalService } from '@zipari/shared-sbp-services';
import { isEmpty } from 'lodash';
import { forkJoin, of, range, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { Observable } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, flatMap, map, shareReplay } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Subscription } from 'rxjs';
import { IndividualEnrollmentStepTypes } from '../../../enrollment/src/lib/individual/individualEnrollment';

export const exceptionToAnalytics = {
    login_register: 'login_register',
    individual: 'individual',
};

export interface WorkflowError {
    [zipari: string]: any;
    message?: string;
    modal?: boolean;
    error?: HttpErrorResponse;
    banner?: boolean;
}
/*
    Base class for workflow functionality. e.g. complete step, back step
    Meant to be extended by business specific workflows. Do not inject this class directly
    Note the ts generic allows you to type the workflow values
*/
export class WorkflowService<T> {
    readonly workflowEndpoint: string = '/api/workflows/';

    public quoteDetailUnauthenticatedPayload: QuoteDetailPayload;

    private errorSubscription: Subscription;
    private showingErrorModal: boolean = false;
    private _steps = new BehaviorSubject<any>(null);
    private _exitRoute: string;

    constructor(
        public loggerService: LoggerService,
        public locationStrategy: LocationStrategy,
        public analyticsService: AnalyticsService,
        public http: HttpClient,
        public modalService: ModalService,
        public router: Router
    ) {
        this._workflow
            .pipe(
                // this will keep us from submitting duplicate workflow events
                distinctUntilChanged((prev, curr) => prev && curr && JSON.stringify(prev) === JSON.stringify(curr)),
                filter((workflow) => !!workflow),
                filter((workflow) => {
                    const workflowType = getValue(workflow, 'current_step.schema.type');
                    // this checks if we are in the dashboard
                    const isApplicationsInUrl = !isEmpty(document.URL.match('applications'));
                    // if we are in the dashboard, then we are not in a workflow and
                    // so we should filter out workflow events
                    if (isApplicationsInUrl && workflow.type === 'individual') {
                        return false;
                    }

                    return !!workflowType && !exceptionToAnalytics[workflowType];
                }),
                debounceTime(analyticsService.analyticsDebounce)
            )
            .subscribe((workflow) => {
                this.analyticsService.setWorkflowContext(this.CXWorkflowContext);

                const workflowType = getValue(workflow, 'current_step.schema.type');

                if (!!workflowType && !exceptionToAnalytics[workflowType] && this.CXWorkflowContext) {
                    const dictionary_attributes = {
                        eventLabel: this.CXWorkflowContext.title,
                        eventCategory: this.CXWorkflowContext.key,
                        pageName: workflowType,
                    };

                    const finalAttributesForCX = {
                        ...this.CXWorkflowContext,
                        ...dictionary_attributes,
                    };

                    const members: Array<any> = getValue(workflow, 'values.members');
                    if (members) {
                        finalAttributesForCX['numOfApplicants'] = members.length;
                    }

                    // We need separate attributes for CX and GA since CX can't handle objects
                    const finalAttributesForGA = cloneObject(finalAttributesForCX);

                    if (!!getValue(workflow, 'values.plans')) {
                        finalAttributesForGA['plans'] = getValue(workflow, 'values.plans');
                        finalAttributesForCX['plans'] = getValue(workflow, 'values.plans');
                    }

                    if (!!getValue(workflow, 'values.quoted')) {
                        finalAttributesForGA['quoted'] = getValue(workflow, 'values.quoted');
                        finalAttributesForCX['quoted'] = getValue(workflow, 'values.quoted');
                    }

                    this.analyticsService.dispatchAnalytics(
                        {
                            GAKey: validGAEvents.virtualPageView,
                            CXKey: validCXEvents.virtualPageView,
                            PosthogKey: validPosthogEvents.virtualPageView,
                        },
                        finalAttributesForCX,
                        finalAttributesForGA
                    );
                }
            });

        this.errorSubscription = this._error.subscribe((errorConfig) => {
            this.handleError(errorConfig);
        });
    }

    public get steps$() {
        return this._steps.asObservable();
    }

    private _workflow = new BehaviorSubject<Workflow<T>>(new Workflow<T>());

    public get workflow(): Workflow<T> {
        let workflow = this._workflow.getValue();

        workflow = this.addHelpersToWorkflow(workflow);

        return workflow;
    }

    public _loading = new BehaviorSubject<boolean>(false);

    public get loading(): Observable<boolean> {
        return this._loading.asObservable();
    }

    public updateLoading(isLoading: boolean) {
        this._loading.next(isLoading);
    }

    private _error = new Subject<Error | WorkflowError>();
    private _showErrorBanner = new BehaviorSubject<boolean>(false);

    public get error(): Observable<any> {
        return this._error.asObservable();
    }

    public get showErrorBanner$(): Observable<boolean> {
        return this._showErrorBanner.asObservable();
    }

    private _errorConfigs: any;

    public get errorConfigs() {
        return this._errorConfigs || {};
    }

    public set errorConfigs(errorConfigs) {
        this._errorConfigs = errorConfigs;
    }

    public get workflow$(): Observable<Workflow<T>> {
        return this._workflow.asObservable().pipe(
            map((workflow) => {
                workflow = this.addHelpersToWorkflow(workflow);

                return workflow;
            })
        );
    }

    public get workflowData$(): Observable<T> {
        return this._workflow.pipe(map((workflow) => workflow.data));
    }

    public get workflowValues$(): Observable<T> {
        return this._workflow.pipe(map((workflow) => workflow.values));
    }

    public get CXWorkflowContext(): workflowContext | null {
        const currentStepSchema = getValue(this.workflow, 'current_step.schema');
        const type = getValue(this.workflow, 'type');

        return currentStepSchema
            ? {
                  web_session_id: this.workflow.web_session_id,
                  webuser_id: this.workflow.webuser_id,
                  workflow_id: this.workflow.id,
                  title: currentStepSchema.label,
                  key: currentStepSchema.description,
                  type,
              }
            : null;
    }

    public get exitRoute(): string {
        return this._exitRoute;
    }

    public set exitRoute(route: string) {
        this._exitRoute = route;
    }

    public get isAppConverted() {
        // todo: set this up in future with BE param to identify if it is quote to enrollment wf
        return this.workflow.data && this.workflow.data.hasOwnProperty('old_workflow_id') && this.workflow.data.old_workflow_id;
    }

    public getWorkflow(workflowId = this.workflow.id): Observable<Workflow<T>> {
        return this.http.get<Workflow<T>>(`${this.workflowEndpoint}${workflowId}/`);
    }

    getWorkflowByStateId(workflowId: number, state_id): Observable<Workflow<T>> {
        return this.http.get<Workflow<T>>(`${this.workflowEndpoint}${workflowId}/?state_id=${state_id}`);
    }

    public setWorkflow(workflowStepResponse: WorkflowStepDetails<T>): void {
        this._workflow.next(workflowStepResponse.workflow);
        this._steps.next(workflowStepResponse.workflow.steps);
    }

    handleError(errorConfig: WorkflowError): void {
        this._loading.next(false);
        const apiError: HttpErrorResponse = errorConfig.error;

        // p1 -> custom user error sent from BE
        const userError = getValue(apiError, 'error.errors.0.user_error');
        // p2 -> default message from errorConfig (FE config)
        const configError = errorConfig.message;
        // p3 -> anything coming in top level error.message (backwards compatible)
        const fallbackError = getValue(errorConfig, 'error.message');

        const errorMessage: string = userError || configError || fallbackError;

        if (errorConfig.modal) {
            if (!this.showingErrorModal) {
                // protects from multiple notifications from cascading errors
                this.showingErrorModal = true;
                const clearHasError: Function = () => {
                    this.showingErrorModal = false;
                };

                const confirmMessage: string = errorConfig.confirm ? errorConfig.confirm : 'Okay';

                this.modalService.presentModal(
                    {
                        body: errorMessage,
                        confirm: confirmMessage,
                    },
                    {},
                    clearHasError
                );
            }
        } else if (errorConfig.banner) {
            this._showErrorBanner.next(true);
        } else {
            this.loggerService.error(errorMessage);
        }
    }

    public startWorkflow(workflowType: string, customBody: any = {}, enrollQueryParam: string = ''): Observable<Workflow<T>> {
        let endpoint: string = this.workflowEndpoint;
        let queryParams: string = window.location.search;
        queryParams = queryParams && enrollQueryParam ? `${queryParams}${enrollQueryParam}` : queryParams || enrollQueryParam;
        this._workflow.next(null);
        this._loading.next(true);

        // add query params to new workflow
        endpoint += queryParams;

        return this.http.post<Workflow<T>>(endpoint, { type: workflowType, ...customBody }).pipe(
            tap((response) => {
                this._workflow.next(response);
                this._steps.next(response.steps);
                this._loading.next(false);
            }),
            catchError(() => {
                this._loading.next(false);
                return of();
            })
        );
    }

    public continueWorkflow(id: number, state_id = ''): Observable<Workflow<T>> {
        this._workflow.next(null);
        this._loading.next(true);
        const workflowObservable = id && state_id ? this.getWorkflowByStateId(id, state_id) : this.getWorkflow(id);

        return workflowObservable.pipe(
            tap((response) => {
                this._workflow.next(response);
                this._steps.next(response.steps);
                this._loading.next(false);
            }),
            finalize(() => {
                this._loading.next(false);
                return of();
            })
        );
    }

    public previousStep$(): Observable<any> {
        const ignoreFromPreviousStep = {
            login_register: true,
        };

        const extendedTimeout = 120000;
        this._loading.next(true);

        let lastNotSkippedStep;

        // make sure we don't go back to a step that was skipped
        // start -2... 1 for zero index and 1 to start at most previous step
        for (let i = this.workflow.current_step_number - 2; i >= 0; i--) {
            const currentStep = this.workflow.steps[i];
            if (currentStep && currentStep.state !== 'skipped' && !ignoreFromPreviousStep[currentStep.schema.type]) {
                lastNotSkippedStep = i;
                break;
            }
        }

        // check to make sure that `lastNotSkippedStep` was set within for loop
        // if lastNotSkippedStep is still undefined it means someone is calling previous step on the first step....
        // why would one do that?... the world may never know
        if (typeof lastNotSkippedStep === 'number') {
            return this.http
                .patch<WorkflowStepDetails<T>>(`${this.workflowEndpoint}${this.workflow.id}/steps/${lastNotSkippedStep + 1}/`, {
                    state: 'in_progress',
                    headers: new HttpHeaders({ timeout: `${extendedTimeout}` }),
                })
                .pipe(
                    tap((response) => {
                        this._loading.next(false);
                        this.setWorkflow(response);
                        scrollToTop();
                    })
                );
        } else if (this.exitRoute) {
            this.router.navigate([this.exitRoute]);
        } else {
            console.error('Previous step called on first step. This is an error. Specify an exit route from first step.');
        }
        return of({});
    }

    public previousStep() {
        this.previousStep$().subscribe();
    }

    public completeStep(data = {}, config: any = {}, shouldLoaderContinue?: boolean): void {
        const extendedTimeout = 120000;
        const forward = typeof config.forward === 'boolean' ? config.forward : true;
        const step = config.step || this.workflow.current_step_number;

        // On each complete step, add workflow route to browser history
        //   this allows browser back button to move back in the workflow
        history.pushState(null, null, location.href);

        this._loading.next(true);
        this.handleCheckoutAnalytics();

        /* Commenting this out for now. We shouldn't need updatedCoverageEffectiveDate anymore,
        but leaving it just in case.*/

        // const updatedCoverageEffectiveDate = getValue(this.workflow.values, 'special_enrollment.updated_coverage_effective_date');
        // if (updatedCoverageEffectiveDate) {
        //     data['coverage_effective_date'] = updatedCoverageEffectiveDate;
        // }

        this.http
            .put<WorkflowStepDetails<T>>(`${this.workflowEndpoint}${this.workflow.id}/steps/${step}/`, {
                values: data ? data : {},
                state: 'complete',
                headers: new HttpHeaders({ timeout: `${extendedTimeout}` }),
            })
            .subscribe(
                (workflowStepResponse) => {
                    this._workflow.next({
                        ...workflowStepResponse.workflow,
                    });
                    if (!shouldLoaderContinue) {
                        this._loading.next(false);
                    }
                    if (forward) {
                        this._steps.next(workflowStepResponse.workflow.steps);
                        scrollToTop();
                    }
                },
                (error) => {
                    let errorConfig: WorkflowError = Object.assign({}, this.errorConfigs.completeStep, { error });
                    const currentStepSchema: WorkflowStepSchema = this.workflow.current_step.schema;

                    if (currentStepSchema.key === IndividualEnrollmentStepTypes.billing_confirmation) {
                        errorConfig = { banner: true };
                    }
                    this._error.next(errorConfig);
                }
            );
    }

    public completeStepObs$(data?): Observable<any> {
        const extendedTimeout = 120000;
        this._loading.next(true);
        this.handleCheckoutAnalytics();

        return this.http
            .put<WorkflowStepDetails<T>>(`${this.workflowEndpoint}${this.workflow.id}/steps/${this.workflow.current_step_number}/`, {
                values: data ? data : {},
                state: 'complete',
                headers: new HttpHeaders({ timeout: `${extendedTimeout}` }),
            })
            .pipe(
                tap((workflowStepResponse) => {
                    this._workflow.next({
                        ...workflowStepResponse.workflow,
                    });
                    this._steps.next(workflowStepResponse.workflow.steps);
                    this._loading.next(false);
                    scrollToTop();
                }),
                catchError((err) => {
                    this.loggerService.error('err caught', err);
                    this._loading.next(false);

                    return throwError(err);
                })
            );
    }

    public patchWorkflow(
        data,
        stepNumber: number,
        setWorkflow: boolean = true,
        shouldLoaderContinue?: boolean
    ): Observable<WorkflowStepDetails<T>> {
        const extendedTimeout = 120000;

        if (this.workflow) {
            this._loading.next(true);
            return this.http
                .patch<WorkflowStepDetails<T>>(`${this.workflowEndpoint}${this.workflow.id}/steps/${stepNumber}/`, {
                    values: data,
                    headers: new HttpHeaders({ timeout: `${extendedTimeout}` }),
                })
                .pipe(
                    shareReplay(),
                    tap((response: WorkflowStepDetails<T>) => {
                        if (setWorkflow) {
                            this.setWorkflow(response);
                        }
                    }),
                    finalize(() => {
                        if (!shouldLoaderContinue) {
                            this._loading.next(false);
                        }
                    })
                );
        } else {
            return of();
        }
    }

    public skipStep(stepNumber: number): Observable<any> {
        return this.http.patch(`${this.workflowEndpoint}${this.workflow.id}/steps/${stepNumber}/`, { state: 'skipped' });
    }

    public restoreStep(stepNumber: number): Observable<any> {
        return this.http.patch(`${this.workflowEndpoint}${this.workflow.id}/steps/${stepNumber}/`, { state: 'not_started' });
    }

    public skipToStep(stepNumber: number) {
        const numberStepsToSkip = stepNumber - this.workflow.current_step_number;
        if (numberStepsToSkip > 0) {
            range(this.workflow.current_step_number, numberStepsToSkip)
                .pipe(
                    flatMap((stepToSkip) => {
                        return this.workflow.steps[stepToSkip].state !== 'complete' ? this.skipStep(stepToSkip) : of(null);
                    })
                )
                .subscribe();
        }
        this.setCurrentStep(stepNumber);
    }

    public skipCurrentAndGoToNext() {
        const currentStep = this.workflow.current_step_number;
        this.setCurrentStep(currentStep + 1);
        this.http
            .patch(`${this.workflowEndpoint}${this.workflow.id}/steps/${currentStep}/`, { state: 'skipped' })
            .subscribe(null, (error) => {
                const errorConfig = Object.assign({}, this.errorConfigs.skipCurrentAndGoToNext, { error });
                this._error.next(errorConfig);
            });
    }

    public goToStep(stepId: number) {
        this.goToStep$(stepId).subscribe(
            (response) => {
                this.setWorkflow(response);
                scrollToTop();
            },
            (error) => {
                const errorConfig = Object.assign({}, this.errorConfigs.goToStep, { error });
                this._error.next(errorConfig);
            }
        );
    }

    public goToStep$(stepId) {
        this._loading.next(true);
        const extendedTimeout = 120000;

        return this.http
            .patch<WorkflowStepDetails<T>>(`${this.workflowEndpoint}${this.workflow.id}/steps/${stepId}/`, {
                state: 'in_progress',
                headers: new HttpHeaders({ timeout: `${extendedTimeout}` }),
            })
            .pipe(
                tap(() => {
                    this._loading.next(false);
                })
            );
    }

    public setLoading(isLoading: boolean) {
        // we can't do this with a real setter
        this._loading.next(isLoading);
    }

    public sendError(config: any, error: Error) {
        const errorConfig = Object.assign({}, config, { error });
        this._error.next(errorConfig);
    }

    replaceValuesOnOtherStepsIfTheyExist(currentStepData: any, keysToCheck: Array<string>, currentStepIndex: number): Observable<any> {
        const stepValuesToAdjust: Array<{ newValue: any; ind: number; stepInd: number }> = [];
        this._loading.next(true);

        this.workflow.steps.forEach((step, ind) => {
            const stepValues = {};

            const filteredKeys = keysToCheck.filter((valueKey) => !!this.workflow.steps[ind].values[valueKey] && ind !== currentStepIndex);

            filteredKeys.forEach((key) => {
                stepValues[key] = currentStepData[key];
            });

            if (filteredKeys.length > 0) {
                stepValuesToAdjust.push({ newValue: stepValues, ind, stepInd: ind + 1 });
            }
        });

        const result = { currentStepData, stepValuesToAdjust };

        // if there are steps to be changed then make the patches OR stub out the forkjoin in the case where there are
        // no steps where we need to change the keys provided
        let observableArr: Array<Observable<any>> = [];
        if (result.stepValuesToAdjust.length > 0) {
            observableArr = result.stepValuesToAdjust.map((stepValueToAdjust: { newValue: any; ind: number; stepInd: number }) => {
                return this.patchWorkflow(stepValueToAdjust.newValue, stepValueToAdjust.stepInd, false);
            });
        } else {
            observableArr.push(of(true));
        }

        return forkJoin(observableArr).pipe(
            map(() => {
                return result;
            })
        );
    }

    getWorkflowsByPolicyNumber(policy_number) {
        const options = policy_number ? { params: new HttpParams().set('data__policy_number', policy_number) } : {};
        return this.http.get<any>(`api/workflows/`, options);
    }

    handleBackButtonClick(disableBrowserBack: Function = () => false) {
        // Set timeout since browser back can't be cancelled
        // This allows our internal navigation to run after
        setTimeout(() => {
            if (!disableBrowserBack()) {
                this.previousStep();
            } else {
                this.router.navigate([this.exitRoute]);
            }
        }, 0);
    }

    /** fires off checkout google analytics ecommerce event only when step is configured with "checkout" boolean in step config */
    public handleCheckoutAnalytics(): void {
        const stepSchemaConfig = getValue(this.workflow, 'current_step.schema.config');

        if (stepSchemaConfig && stepSchemaConfig['checkout']) {
            const plans = getValue(this.workflow, 'values.plans');

            this.analyticsService.dispatchAnalytics(
                {
                    GAKey: validGAEvents.checkout,
                    CXKey: validCXEvents.checkout,
                    PosthogKey: validPosthogEvents.checkout,
                },
                { plans: plans }
            );
        }
    }

    public tickWorkflowSubscription() {
        this._workflow.next(cloneObject(this.workflow));
    }

    /** Allows us to retrieve the step number based on a given step type */
    getStepNumberByStepType(type: string) {
        const stepIndex = this.workflow.steps.findIndex((step) => {
            const stepType = getValue(step, 'schema.type');

            return stepType === type;
        });

        if (stepIndex < 0) {
            return null;
        } else {
            return stepIndex + 1;
        }
    }

    checkForValueInStepAndPatch(key: string, newValue: any, step?: number) {
        const filteredStep = this.workflow.steps.filter((step) => {
            const value = getValue(step.values, key);
            if (value === '') {
                return true;
            }

            return !!value;
        });

        return this.patchWorkflow(newValue, step || filteredStep[0]['step_number'], false, true);
    }

    /** Allows us to check if a particular step type exists in the current workflow */
    checkIfStepTypeExists(type: string) {
        return !!this.workflow.steps.find((step) => {
            const stepType = getValue(step, 'schema.type');
            const stepState = getValue(step, 'state');

            return stepType === type && stepState !== 'skipped';
        });
    }

    private addHelpersToWorkflow(workflow) {
        if (!workflow) {
            return workflow;
        }

        workflow['helpers'] = {
            checkForValueInStepAndPatch: (key: string, newValue: any, step?: number) =>
                this.checkForValueInStepAndPatch(key, newValue, step),
            checkIfStepTypeExists: (type: string) => this.checkIfStepTypeExists(type),
            getStepNumberByStepType: (type: string) => this.getStepNumberByStepType(type),
        };

        return workflow;
    }

    private setCurrentStep(stepNumber: number) {
        this._workflow.next({
            ...this.workflow,
            current_step_number: stepNumber,
        });
    }
}
